<template>
  <Auth>
    <section class="card-auth">
      <div class="logo-sesi">
        <ApplicationLogo />
      </div>

      <b-alert
          v-if="alert.show"
          show
          fade
          class="mb-2"
          variant="danger"
      >
        <div class="custom-alert">
              <span>
                <BIconExclamationCircle />
                {{ alert.message }}
              </span>
        </div>
      </b-alert>

      <b-card-title
          class="mb-1 font-weight-bold"
          title-tag="h2"
      >
        Esqueceu sua senha?
      </b-card-title>
      <b-card-text class="mb-2">
        Preencha seu e-mail e nós enviaremos as próximas instruções.
      </b-card-text>

      <b-form
          class="auth-login-form mt-2"
          @submit.prevent="forgotPassword"
      >
        <!-- email -->
        <b-form-group
            label="E-mail"
            label-for="login-email"
        >

          <b-form-input
              id="login-email"
              v-model="forgot.email"
              @focus="alert.show = false"
              name="login-email"
              :class="formErrors.email ? 'border border-danger' : ''"
              placeholder="email@email.com"
              autocomplete="off"
          />
          <small class="text-danger">{{ formErrors.email }}</small>
        </b-form-group>

        <!-- submit buttons -->
        <button :disabled="disabledButton" type="submit" class="btn button-form btn-block">
          {{ msgButton }}
        </button>
      </b-form>

      <b-card-text class="text-center mt-2">
        <b-link :to="{name:'auth-login'}">
          <BIconChevronLeft/>
          <span class="ml-1">Voltar ao login</span>
        </b-link>
      </b-card-text>
    </section>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import {
  BOverlay,
  BIconGoogle,
  BIconExclamationCircle,
  BIconChevronLeft,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import { validation } from "@core/mixins/validation/validation";
import { messages } from "@core/mixins/validation/messages";

export default {
  components: {
    Auth,
    BOverlay,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BAlert,
    BButton,
    BIconGoogle,
    BIconExclamationCircle,
    BIconChevronLeft,
    ApplicationLogo,
  },
  mixins: [validation, messages],

  data() {
    return {
      forgot: {
        email: localStorage.emailForgotPassword || ''
      },
      alert: {
        show: false,
        message: '',
        variant: ''
      },

      loadingShow: true,

      disabledButton: false,
      msgButton: 'Enviar',

      formErrors: {},
    }
  },

  methods: {
    async forgotPassword() {
      if(this.validation()) {
        this.buttonLoading(true);

        await this.$http.post(this.$api.EsqueceuSenha, this.forgot)
            .then((response) => {
              const status = response.request.status;

              if(status === 204) {
                // Cadastra o e-mail no localStorage para caso o usuário não receber o e-mail,
                // poder reeenviar com o campo e-mail já preenchido.
                localStorage.setItem('emailForgotPassword', this.forgot.email);

                this.$router.push({ path: `/auth/verificacao-email/${this.forgot.email}` });
              }
            })
            .catch((error) => {
              this.errorHandling(error.response);
            })

        this.buttonLoading(false);
      }
    },

    errorHandling(response) {
      const status = response.status;
      const message = response.data.error || this.impossibleMsg;

      if(status === 404) {
        this.showAlert(
            message,
            'danger'
        );
      } else {
        this.showAlert(
            this.impossibleMsg,
            'danger'
        );
      }
    },

    validation() {
      this.emailValidate(this.forgot.email);

      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
